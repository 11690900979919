import * as React from 'react';
import styles from './ReferenceSidebar.module.scss';
import { Link } from 'gatsby';
import { ReferenceSidebarEdge } from '../../templates/reference';

export interface ReferenceSidebarProps {
  data: ReferenceSidebarEdge[];
}

const ReferenceSidebar: React.FunctionComponent<ReferenceSidebarProps> = ({}) => {
  return (
    <div className={styles.ReferenceSidebar}>
      <section className={styles.Section}>
        <div className={styles.SectionTitle}>Introduction</div>
        <ul className={styles.SectionParts}>
          <li>
            <Link to='/reference/' activeClassName={styles.Active}>
              Overview
            </Link>
          </li>
          <li>
            <Link
              to='/reference/#developer-support'
              activeClassName={styles.Active}
              partiallyActive={true}
            >
              Developer Support
            </Link>
          </li>
        </ul>
      </section>
      <section className={styles.Section}>
        <div className={styles.SectionTitle}>Drop-In Solutions</div>
        <ul className={styles.SectionParts}>
          <li>
            <Link to='/reference/tags/searchkit/' activeClassName={styles.Active}>
              Search Kit
            </Link>
          </li>
          <li>
            <Link to='/reference/tags/displaykit/' activeClassName={styles.Active}>
              Display Kit
            </Link>
          </li>
        </ul>
      </section>
      <section className={styles.Section}>
        <div className={styles.SectionTitle}>REST APIs</div>
        <ul className={styles.SectionParts}>
          <li>
            <Link to='/reference/rest-api/' activeClassName={styles.Active}>
              Overview
            </Link>
          </li>
          <li>
            <Link to='/reference/rest-api/admin/' activeClassName={styles.Active}>
              Admin API
            </Link>
          </li>
          <li>
            <Link to='/reference/rest-api/reviews/' activeClassName={styles.Active}>
              Reviews API
            </Link>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default ReferenceSidebar;
